<template>
  <div class="services-page">
    <Breadcrumbs :breadcrumbs="breadcrumbsList" />

    <div class="services-page__header">
      <div class="services-page__title-wrapper">
        <h1 class="services-page__title font font_uppercase font_title-l font_bold">
          Услуги
        </h1>
        <span
          v-if="!$fetchState.pending && totalServices > 0"
          class="services-page__products-count font font_sm font_grey"
        >
          {{ totalServicesPhrase }}
        </span>
      </div>
      <Button
        theme="secondary"
        data-test="services-filter-activator"
        class="service-page__control"
        @click="setFilterState(true)"
      >
        <span>
          Все фильтры
          <span class="service-page__control-count">
            {{ checkedFiltersNum }}
          </span>
        </span>
        <template #iconRight>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.43078 14.9887H2.40234"
              stroke="#12121D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.5988 14.9889C17.5988 16.3144 16.5243 17.3889 15.1988 17.3889C13.8733 17.3889 12.7988 16.3144 12.7988 14.9889C12.7988 13.6624 13.8733 12.5889 15.1988 12.5889C16.5243 12.5889 17.5988 13.6624 17.5988 14.9889Z"
              stroke="#12121D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.5693 5.21847H17.5987"
              stroke="#12121D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.40234 5.21836C2.40234 6.54483 3.47685 7.61836 4.80234 7.61836C6.12783 7.61836 7.20234 6.54483 7.20234 5.21836C7.20234 3.89287 6.12783 2.81836 4.80234 2.81836C3.47685 2.81836 2.40234 3.89287 2.40234 5.21836Z"
              stroke="#12121D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>
      </Button>
      <div>
      </div>
      <div v-if="!mqIsMobile" class="services-page__filters">
        <CustomSelect
          v-model="selectedProfessionFilteres"
          class="services-page__filter-item"
          label-option-name="name"
          :options="professionFilterOptions"
          small
          @input="getFilteredServices"
        />
        <CustomSelect
          v-model="selectedTypeFilteres"
          class="services-page__filter-item"
          label-option-name="name"
          :options="typeFilterOptions"
          small
          @input="getFilteredServices"
        />
        <CustomSelect
          v-model="selectedCityFilteres"
          class="services-page__filter-item"
          label-option-name="name"
          :options="cityFilterOptions"
          small
          @input="getFilteredServices"
        />
      </div>
    </div>

    <div v-if="!mqIsMobile" class="services-page__times">
      <Button
        class="services-page__times_button"
        :class="{ 'active' : selectedTimeFilteres.code === 'future' }"
        @click="selectedTimeFilteres.code = 'future'; getFilteredServices()"
      >
        Актуальные
      </Button>
      <Button
        class="services-page__times_button"
        :class="{ 'active' : selectedTimeFilteres.code === 'past' }"
        @click="selectedTimeFilteres.code = 'past'; getFilteredServices()"
      >
        Прошедшие
      </Button>
    </div>

    <LoaderDots v-if="$fetchState.pending" class="services-loader" />

    <div v-if="totalServices === 0">
      <div>К сожалению, список мероприятий пуст.</div>
      <div>Измените настройки фильтра.</div>
    </div>

    <ul v-else-if="services.length" class="services-page__cards">
      <ServiceCard v-for="service in services" :key="service.id" :item="service" />
    </ul>

    <div v-if="mqIsMobile">
      <ModalFilter v-model="isShowFilter">
        <template #content>
          <div v-if="timeFilterOptions.length" class="service__filter-item">
            <div class="service__filter-item-label">
              Время
            </div>
            <CustomSelect
              v-model="selectedTimeFilteres"
              class="services-page__filter-item"
              label-option-name="name"
              :options="timeFilterOptions"
              small
              @input="getFilteredServices"
            />
          </div>
          <div v-if="professionFilterOptions.length" class="service__filter-item">
            <div class="service__filter-item-label">
              Направление
            </div>
            <CustomSelect
              v-model="selectedProfessionFilteres"
              class="services-page__filter-item"
              label-option-name="name"
              :options="professionFilterOptions"
              small
              @input="getFilteredServices"
            />
          </div>
          <div v-if="typeFilterOptions.length" class="service__filter-item">
            <div class="service__filter-item-label">
              Тип
            </div>
            <CustomSelect
              v-model="selectedTypeFilteres"
              class="services-page__filter-item"
              label-option-name="name"
              :options="typeFilterOptions"
              small
              @input="getFilteredServices"
            />
          </div>
          <div v-if="cityFilterOptions.length" class="service__filter-item">
            <div class="service__filter-item-label">
              Город
            </div>
            <CustomSelect
              v-model="selectedCityFilteres"
              class="services-page__filter-item"
              label-option-name="name"
              :options="cityFilterOptions"
              small
              @input="getFilteredServices"
            />
          </div>
        </template>
        <template #footer>
          <div
            v-if="checkedFiltersNum"
            class="filter__clear-button font font_sm font_uppercase"
            data-test="filter-clear"
            @click="clearFilterHandler"
          >
            Очистить
          </div>
          <Button
            full
            class="filter__apply"
            type="submit"
            size="sm"
            @click="setFilterState(false)"
          >
            Показать {{ totalServices ? totalServices : '' }}
          </Button>
        </template>
      </ModalFilter>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/elements/Breadcrumbs';
import ServiceCard from '@/components/service/card';
import LoaderDots from '@/components/elements/LoaderDots';
import CustomSelect from '@/components/elements/CustomSelect';
import Button from '@/components/elements/Button';
import './index.css';

import { phraseDeclension } from '@/plugins/formatting';
import ModalFilter from '~/components/elements/modals/Filter';

export default {
  name: 'ServicePage',
  components: {
    ModalFilter,
    Breadcrumbs,
    ServiceCard,
    LoaderDots,
    CustomSelect,
    Button,
  },
  data() {
    return {
      isShowFilter: false,

      page: 1,
      services: [],
      allFilters: [],
      acceptedFilters: {},
      totalServices: null,
      selectedTimeFilteres: { name: 'Будущие событие', code: 'future' },
      selectedTypeFilteres: { name: 'Все типы событий' },
      selectedCityFilteres: { name: 'Все города' },
      selectedProfessionFilteres: { name: 'Все направления' },
      defaultFilterOptions: {
        time: { name: 'Будущие событие', code: 'future' },
        type: { name: 'Все типы событий' },
        city: { name: 'Все города' },
        profession: { name: 'Все направления' },
      },
      breadcrumbsList: [
        {
          name: 'Главная',
          url: '/',
        },
        {
          name: 'Услуги',
          url: '',
        },
      ],
    };
  },
  async fetch() {
    try {
      await this.getFilteredServices();
      const filters = await this.$api.services.getAllServiceFilters();
      this.allFilters = filters.items;
    } catch (error) {
      console.error(error);
      this.services = {
        items: [],
        range: 0,
      };
      this.totalServices = 0;
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    totalServicesPhrase() {
      return phraseDeclension(this.totalServices, ['событие', 'событий', 'события']);
    },
    typeFilterOptions() {
      const typeFilter = this.allFilters.find((filter) => filter.id === 'type') ?? [];
      const filterOptions = typeFilter?.items ?? [];

      return [this.defaultFilterOptions.type, ...filterOptions];
    },
    cityFilterOptions() {
      const cityFilter = this.allFilters.find((filter) => filter.id === 'place_city') ?? [];
      const filterOptions = cityFilter?.items ?? [];

      return [this.defaultFilterOptions.city, ...filterOptions];
    },
    professionFilterOptions() {
      const professionFilter = this.allFilters.find((filter) => filter.id === 'profession') ?? [];
      const filterOptions = professionFilter?.items ?? [];

      return [this.defaultFilterOptions.profession, ...filterOptions];
    },
    timeFilterOptions() {
      const timeFilter = this.allFilters.find((filter) => filter.id === 'time') ?? [];

      return timeFilter?.items ?? [];
    },
    checkedFiltersNum() {
      let num = 0
      Object.keys(this.acceptedFilters).forEach(key => {
        const item = this.acceptedFilters[key]

        if (item.length) {
          if (key === 'time' && item[0] !== 'future') {
            num ++
          } else if (key !== 'time' && item[0]) {
            num++
          }
        }
      })

      return num
    },
  },
  mounted() {
    this.selectedTimeFilteres = this.timeFilterOptions[0] || this.defaultFilterOptions.time
  },
  methods: {
    async getFilteredServices() {
      this.acceptedFilters.time = [this.selectedTimeFilteres.code];
      this.acceptedFilters.type = [this.selectedTypeFilteres.code];
      this.acceptedFilters.place_city = [this.selectedCityFilteres.id];
      this.acceptedFilters.profession = [this.selectedProfessionFilteres.code];

      try {
        const services = await this.$api.services.getServiceItems({ page: this.page, filter: this.acceptedFilters });
        this.services = services.items ?? [];
        this.totalServices = services.range ?? 0;
      } catch (err) {
        console.error(err);
      }
    },
    async clearFilterHandler() {
      this.selectedTimeFilteres = this.defaultFilterOptions.time
      this.selectedTypeFilteres = this.defaultFilterOptions.type
      this.selectedCityFilteres = this.defaultFilterOptions.city
      this.selectedProfessionFilteres = this.defaultFilterOptions.profession

      await this.getFilteredServices()
    },
    setFilterState(state) {
      this.isShowFilter = state
    },
  },
};
</script>
