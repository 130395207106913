<template>
  <li class="service-card">
    <nuxt-link
      class="service-card__link"
      :to="{
        name: 'services.service',
        params: { service: item.code },
      }"
    >
      <CustomImage
        v-if="item.image && item.image.url"
        class="service-card__img"
        :image="item.image.url"
        size="small"
        retina="medium"
        :alt="item.name"
        aspect-ratio="59.46%"
        :loading="loading"
      />
      <div class="service-card__info">
        <div class="service-card__text-accent">
          <p class="service-card__name font font_title-m font_medium">
            {{ item.name }}
          </p>
        </div>
        <template v-if="itemPrice || itemPrice === 0">
          <p v-if="itemPrice === 0" class="service-card__price service-card__price-from font font_m">
            Бесплатно
          </p>
          <p v-else-if="item.price.value" class="service-card__price font font_m">
            <span v-if="item.price.value.from" class="service-card__price-from">
              от {{ getCurrency(item.price.value.from) }}
            </span>
            <span v-if="item.price.value.to" class="service-card__price-to">
              до {{ getCurrency(item.price.value.to) }}
            </span>
          </p>
        </template>
        <p class="service-card__footer font font_xs font_uppercase font_grey">
          <span v-if="formattedDateTime">{{ formattedDateTime }} •</span>
          <span v-if="item.nearestPlaceName">{{ item.nearestPlaceName }}</span>
        </p>
      </div>
    </nuxt-link>
  </li>
</template>

<script>
import {mapGetters} from 'vuex';
import { dateFormat, weekdayFormatShort } from '@/plugins/formatting';
import './index.css';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ServiceCard',
  components: {
    CustomImage,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    itemPrice() {
      return this.item?.price?.value || 0
    },
    formattedDateTime() {
      const date = this.item.nearestDate;
      const time = this.item.nearestTimeFrom;

      if (!date || !time) return ''

      const dateTime = new Date(date + 'T' + time);

      const formattedDate = dateFormat(
        dateTime,
        {
          month: 'long',
          day: '2-digit',
        },
        'ru-RU',
      );

      const weekDay = weekdayFormatShort(dateTime);

      const formattedTime = dateFormat(
        dateTime,
        {
          hour: 'numeric',
          minute: 'numeric',
          hourCycle: 'h23',
        },
        'ru-RU',
      );

      return `${formattedDate} (${weekDay}), ${formattedTime}`;
    },
  },
};
</script>
