<template>
  <CustomModal
    v-show="isShowModal"
    :visible="isShowModal"
    :empty="true"
    class="modal-filter"
    @close="closeModalHandler"
    @closeOverlay="closeModalHandler"
  >
    <template #content>
      <div class="filter__body">
        <div class="filter__top">
          <button
            class="filter__close"
            type="button"
            @click="closeModalHandler"
          >
            Фильтр
          </button>
        </div>
        <div class="filter__content">
          <slot name="content" />
        </div>
        <div class="filter__bottom">
          <slot name="footer" />
        </div>
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '~/components/elements/CustomModal';

export default {
  name: 'ModalFilter',
  components: {CustomModal},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isShowModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    closeModalHandler() {
      this.isShowModal = false
    },
  },
}
</script>

